import { PrivacyCookieConfig } from "./types";

export const COOKIE_NAME = "_ux_pc";
export const COOKIE_DOMAIN_TRUMPF_COM = ".trumpf.com";
export const COOKIE_DOMAIN_TRUMPF_CN = ".trumpf.cn"; // China
export const COOKIE_EXPIRES = 7;

const getCookieDomain = (): string | undefined => {
    if (
        window.location.hostname.slice(-COOKIE_DOMAIN_TRUMPF_COM.length) ===
        COOKIE_DOMAIN_TRUMPF_COM
    ) {
        return COOKIE_DOMAIN_TRUMPF_COM;
    }
    if (
        window.location.hostname.slice(-COOKIE_DOMAIN_TRUMPF_CN.length) ===
        COOKIE_DOMAIN_TRUMPF_CN
    ) {
        return COOKIE_DOMAIN_TRUMPF_CN;
    }
    return undefined;
};

export const getConfig = (): PrivacyCookieConfig => {
    return {
        cookieName: COOKIE_NAME,
        expires: COOKIE_EXPIRES,
        secure: false,
        sameSite: "lax",
        domain: getCookieDomain(),
    };
};
