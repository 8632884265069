import cookieConsent from "../cookie-consent";
import cookieConsentInitializer from "../cookie-consent/cookieConsentInitializer";
import registerGlobal from "../utils/registerGlobal";
import privacyConsent from "../privacy-consent";
import privacyConsentInitializer from "../privacy-consent/privacyConsentInitializer";

const packageName = "ux-ui-cookie-consent";

const addPackageScripts = (): void => {
    const bundleFilename = `${packageName}.min.js`;
    const bundleScriptNode = document.querySelector<HTMLScriptElement>(
        `script[src*="/${bundleFilename}"]`,
    );
    const baseUrl = bundleScriptNode
        .getAttribute("src")
        .replace(bundleFilename, "");

    const esmModuleScriptNode = document.createElement("script");
    esmModuleScriptNode.src = `${baseUrl}${packageName}/${packageName}.esm.js`;
    esmModuleScriptNode.type = "module";
    bundleScriptNode.insertAdjacentElement("afterend", esmModuleScriptNode);

    const noModuleScriptNode = document.createElement("script");
    noModuleScriptNode.src = `${baseUrl}${packageName}/${packageName}.js`;
    noModuleScriptNode.noModule = true;
    esmModuleScriptNode.insertAdjacentElement("afterend", noModuleScriptNode);
};

addPackageScripts();

registerGlobal(cookieConsent, ["trumpf", "ux", "uiCookieConsent"], window);
cookieConsentInitializer();

registerGlobal(privacyConsent, ["trumpf", "ux", "uiPrivacyConsent"], window);
privacyConsentInitializer();
