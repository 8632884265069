import { privacyConsentClient } from "./privacyConsentClient";
import { privacyConsentInitializer } from "./privacyConsentInitializer";
import { privacyConsentController } from "./privacyConsentController";

export * from "./config";
export * from "./types";
export {
    privacyConsentClient,
    privacyConsentController,
    privacyConsentInitializer,
};

export default {
    ...privacyConsentClient,
    ...privacyConsentController,
};
