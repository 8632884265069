import { PrivacyConsentInitOptions, PrivacyConsentController } from "./types";

const getPrivacyLayer = (): HTMLUxPrivacyLayerElement | null => {
    return document.querySelector(
        "ux-privacy-layer",
    ) as HTMLUxPrivacyLayerElement;
};

const configurePrivacyLayer = (
    privacyLayer: HTMLUxPrivacyLayerElement,
    config: PrivacyConsentInitOptions,
): void => {
    if (config.locale !== undefined) {
        privacyLayer.locale = config.locale;
    }
    if (config.theme !== undefined) {
        privacyLayer.theme = config.theme;
    }
};

const isEnabled = (): boolean => {
    return getPrivacyLayer() !== null;
};

const init = (initOptions?: PrivacyConsentInitOptions): void => {
    const config: PrivacyConsentInitOptions = {
        ...initOptions,
    };
    let privacyLayer: HTMLUxPrivacyLayerElement | null = getPrivacyLayer();
    if (privacyLayer !== null) {
        configurePrivacyLayer(privacyLayer, config);
        return;
    }
    privacyLayer = document.createElement("ux-privacy-layer");
    configurePrivacyLayer(privacyLayer, {
        locale: document.documentElement.lang,
        ...config,
    });
    document.body.appendChild(privacyLayer);
};

const showPrivacyLayer = async (context?: string): Promise<void> => {
    const privacyLayer = getPrivacyLayer();
    if (privacyLayer !== null) {
        await privacyLayer.show(context);
    }
};

export const privacyConsentController: PrivacyConsentController = {
    isEnabled,
    init,
    getPrivacyLayer,
    showPrivacyLayer,
};

export default privacyConsentController;
