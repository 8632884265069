import Cookies from "./cookies";
import { getConfig } from "./config";
import {
    PrivacyConsentClient,
    PrivacyConsentState,
    PrivacySettings,
} from "./types";

const config = getConfig();

const defaultPrivacySettings: PrivacySettings = {
    accepted: undefined,
};

export const isValidState = (data: any): boolean =>
    typeof data === "object" &&
    typeof data.t === "number" &&
    typeof data.accepted === "boolean";

export const setState = (data: PrivacyConsentState): boolean => {
    if (!isValidState(data)) {
        return false;
    }
    Cookies.set(
        config.cookieName,
        JSON.stringify({
            ...defaultPrivacySettings,
            ...data,
        }),
        config,
    );
    return true;
};

export const getState = (): PrivacyConsentState | null => {
    try {
        const dataEncoded: string = Cookies.get(config.cookieName);
        if (dataEncoded === "") {
            return null;
        }
        const data: PrivacyConsentState = JSON.parse(
            Cookies.get(config.cookieName),
        );
        return isValidState(data) ? data : null;
    } catch (err) {
        return null;
    }
};

export const getPrivacySettings = (): PrivacySettings => {
    const data = getState();
    if (data !== null && typeof data.t === "number") {
        return {
            ...defaultPrivacySettings,
            ...data,
        };
    }
    return {
        ...defaultPrivacySettings,
    };
};

export const hasConsented = (): boolean => {
    return typeof getPrivacySettings().accepted === "boolean";
};

export const hasAccepted = (): boolean => {
    return getPrivacySettings().accepted;
};

export const hasRejected = (): boolean => {
    return !getPrivacySettings().accepted;
};

export const consent = (prefs?: PrivacySettings): boolean => {
    return setState({
        ...defaultPrivacySettings,
        ...prefs,
        t: Date.now(),
    });
};

export const privacyConsentClient: PrivacyConsentClient = {
    getState,
    getPrivacySettings,
    hasAccepted,
    hasConsented,
    hasRejected,
    consent,
};

export default privacyConsentClient;
