import { COOKIE_DOMAIN_TRUMPF_CN } from "./config";
import { privacyConsentController } from "./privacyConsentController";

let isInitialized: boolean = false;
let isPrivacyLayerInitialized: boolean = false;
let initializerTimeout: number;

const getIsPrivacyLayerEnabled = (): boolean => {
    if (window.location.hostname.endsWith(COOKIE_DOMAIN_TRUMPF_CN)) {
        return true;
    }
    if (
        (window as unknown as { testWithPrivacyLayerCn: unknown })
            .testWithPrivacyLayerCn === true
    ) {
        return true;
    }
    if (window.location.search.includes("testWithPrivacyLayerCn=true")) {
        return true;
    }
    return false;
};

const initPrivacyLayer = () => {
    if (isPrivacyLayerInitialized) {
        return;
    }
    isPrivacyLayerInitialized = true;
    window.clearTimeout(initializerTimeout);
    window.removeEventListener("DOMContentLoaded", initPrivacyLayer);
    privacyConsentController.init();
};

export const privacyConsentInitializer = (): void => {
    if (!isInitialized && getIsPrivacyLayerEnabled()) {
        isInitialized = true;
        window.addEventListener("DOMContentLoaded", initPrivacyLayer);
        initializerTimeout = window.setTimeout(initPrivacyLayer, 5000);
    }
};

export default privacyConsentInitializer;
