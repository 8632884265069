import { buildQueryString } from "../utils/buildQueryString";
import { CookieSettings } from "./types";
import { CookieLayerViewAction } from "../components/cookie-layer/types";

const TRACKING_API_URL = "";

enum TrackingEventTypes {
    CONSENT = "consent",
    VIEW = "view",
}

enum TrackingConsentCategories {
    ALL = "all",
    REQUIRED_ONLY = "required-only",
    CUSTOM = "custom",
}

type TrackingEventType = TrackingEventTypes.CONSENT | TrackingEventTypes.VIEW;

interface TrackingData extends Record<string, string> {
    event: TrackingEventType;
}

export type TrackingConsentType =
    | TrackingConsentCategories.ALL
    | TrackingConsentCategories.REQUIRED_ONLY
    | TrackingConsentCategories.CUSTOM;

const getTrackingConsentType = (
    settings: CookieSettings,
): TrackingConsentType => {
    const categories: string[] = Object.keys(settings).filter(
        (type) => typeof settings[type] === "boolean",
    );
    const acceptedCookies: string[] = categories.filter(
        (type) => !!settings[type],
    );
    if (acceptedCookies.length === categories.length) {
        return TrackingConsentCategories.ALL;
    } else if (acceptedCookies.length === 1) {
        return TrackingConsentCategories.REQUIRED_ONLY;
    }
    return TrackingConsentCategories.CUSTOM;
};

const createScopeId = (length: number): string => {
    let result = "";
    const chars =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    for (let i: number = 0; i < length; i++) {
        result += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    return result;
};

let scope: string = createScopeId(16);
let disableTracking: boolean = false;

const trackEvent = async (data: TrackingData): Promise<void> => {
    if (disableTracking) {
        return;
    }
    if ((TRACKING_API_URL as string) === "") {
        disableTracking = true;
        return;
    }
    const params: string = buildQueryString(data);
    const request: string = `${TRACKING_API_URL}?${params}`;
    try {
        await fetch(request);
    } catch {
        // catch silently
    }
};

export const trackCookieSettings = (
    event: CustomEvent<CookieSettings>,
): void => {
    const cookieLayer: HTMLUxCookieLayerElement =
        event.target as HTMLUxCookieLayerElement;
    const cookies: string[] = Object.keys(event.detail)
        .filter((setting) => typeof event.detail[setting] === "boolean")
        .filter((setting) => !!event.detail[setting]);
    trackEvent({
        event: TrackingEventTypes.CONSENT,
        consent: getTrackingConsentType(event.detail),
        cookies: cookies.join(","),
        locale: cookieLayer.locale,
        scope,
    });
};

export const trackCookieLayerShow = (
    event: CustomEvent<CookieLayerViewAction>,
): void => {
    const cookieLayer: HTMLUxCookieLayerElement =
        event.target as HTMLUxCookieLayerElement;
    if (event.detail.open) {
        scope = createScopeId(16);
    }
    trackEvent({
        event: TrackingEventTypes.VIEW,
        view: event.detail.view,
        locale: cookieLayer.locale,
        scope,
    });
};
